<template>
  <div :class="`flex flex-col justify-between items-center ${!isMobileDevice ? 'cardladder-container p-7-5' : 'flex-1 p-7-5 sm:p-4'}`">
    <div class="flex flex-col flex-1">
      <div class="flex justify-between">
        <div class="text-center text-xl25 font-bold mx-auto md:text-xl">
          {{$t('cardladder.what_is')}}
        </div>
      </div>
    
      <div class="text-container mt-2-4 text-n-xl xl:mt-7-5 md:text-n-lg">
        {{$t('cardladder.help_message')}}
      </div>

      <div class="flex flex-col mt-auto">
        <a
          class="flex justify-center items-center text-n-xl font-bold mt-7-5 hover:opacity-60"
          href="https://www.cardladder.com/help/faq"
          rel="noopener noreferrer" 
          target="_blank"
        >
          {{$t('navigation.learn_more')}}
        </a>

        <ButtonV2
          @onClick="hideModal"
          class="mt-s24"
          testId="btn-gotit"
          :label="$t('navigation.got_it')"
          wide
        />
      </div>
    </div>
  </div>
</template>

<script>
  import ButtonV2 from '@/stories/misc/ButtonV2';

  export default {
    name: 'CardLadderHelp',
    components: {
      ButtonV2,
    },
  };
</script>

<style scoped>
.cardladder-container {
  width: 450px;
}
.text-container {
  @apply flex justify-between items-center text-text-body font-semibold;
}
</style>
